
import React from 'react';
import Modal from 'react-modal';
import { setCookie } from '../../inc/cookies';

export default class AggrementsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="modal inmodal">
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
            <Modal
              isOpen={this.props.isOpen}
              style={{
                content: {
                  position: 'null',
                  top: 'null',
                  left: 'null',
                  right: 'null',
                  bottom: 'null',
                  border: 'none',
                  background: '#fff',
                  padding: 'null',
                  maxWidth: '50%',
                  maxHeight: '50%',
                  minWidth: '350px'
                },
                overlay: {
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(0,0,0,00.1)',
                  zIndex: 2500
                }
              }}
            >
              <div>
                <div className="modal-header d-block text-center text-darkblue">
                  <h4 className="modal-title">Satış Kuralları</h4>
                  <h5 className="font-normal">Satış Kuralları</h5>
                </div>
                <div className="modal-body text-darkblue" style={{ maxHeight: '240px', overflowY: 'auto' }}>
                  <div>
                    <h1>Satış Garanti Satış Kuralları <b className="text-danger">(05.03.2025)</b></h1>
                    <ul className="list-unstyled">
                      <li className="p-2"><b>1-)</b> CRM’de potansiyel kaydı açılan müşteriler ilk <span className="text-danger">15 gün</span> kaydı ilk açan kişiye ait olmaktadır. Burada bakılacak nokta kaydı açan kişinin ilk kayıt tarihidir. (<span className="text-danger">Açmadı, Meşgul, Müsait Değil vs. durumlarında hiçbir kayıt 15 gün geçmeden kapatılamayacaktır.</span>) Müşteri hesap kesim tarihi, ödeme müsaitliği için tarih verdiği takdirde ve bu tarih CRM kayıtlarında varsa o tarih beklenip sonrasında (<span className="text-danger">Ödeme gerçekleşmez ise</span>) başkası tarafından aranabilir. (Kaydı ilk açan kişi veya başka kişide olsa sürekli kayıt revize edilemez. Bu gibi bir durumda ilk giriş hareketine bakılacaktır ve kim satarsa ciro o kişiye aittir.)</li>
                      <li className="p-2"><b>2-)</b> CRM kayıtlarında potansiyel üye, kuralımız olan <span className="text-danger">15 gün</span> sonrasına herhangi bir zaman dilimine öteler ve temsilci örnek olarak <span className="text-danger">16 gün</span> sonraya görüşme kaydı oluşturmuş dahi olsa farklı bir arkadaşımız o üyenin satışını gerçekleştirebilir. Bunun için farklı bir satış temsilcisi <span className="text-danger">15 gün</span> kuralını beklemek durumunda değildir.</li>
                      <li className="p-2"><b>3-)</b> Tele satış tarafından sahaya yapılan yönlendirmelerde saha eğer o müşteriyi ziyaret etmeyip sadece telefonla iletişime geçiyorsa ve sonrasında o satışı Tele satış <span className="text-danger">tek başına kapatmış olduğunda</span> sahaya ortak yazılmayacaktır. (Saha sahada olmak zorunda ve fiziki olarak şirket aracı ile ziyarette bulunmak ve sunum yapmak zorunda) aksi halde Tele satışa tek yazılır.</li>
                      <li className="p-2"><b>4-)</b> CRM kaydı bulunmayan satış danışmanı başka birisi tarafından o potansiyel üyeye satış yapıldığında herhangi bir ciro hakkı talep edemez, CRM kayıtları eksiksiz girilmesi gerekmektedir.</li>
                      <li className="p-2"><b>5-)</b> Yönlendirmelerde yönlendirme yapan Tele satışta olan arkadaşım <span className="text-danger">1 ay</span> saha ile ortak yürütebilir ve saha o müşteriyi ziyaret gerçekleştirdiyse satışı yönlendiren kişi satmış olsa da satış ortak yazılır. Bu yönlendirmede ilk kayıt tarihi <span className="text-danger">1 ayı</span> geçmişse başka bir Tele satışta olan arkadaşımız arama yapabilir ve istediği saha ile çalışabilir ve ona yönlendirme yapabilir. Saha ziyaretlerde yaptığı bölgede <span className="text-danger">15 gün</span> süre içinde bölgesine dönse bile satışı kapatabilir.</li>
                      <li className="p-2"><b>6-)</b> Paket sunumlarında paket içerik ürünlerine dair tüm dokümanlar, bilgilendirmeler ve güncellemeler paylaşılmaktadır. Doğru ürün bilgisi için bunların okunması ve takip edilmesi, üyelere doğru bilgi paylaşımı için dokümanların ve maillerin okunması gerekmektedir.</li>
                      <li className="p-2"><b>7-)</b> Tele Satış tarafından saha tarafına atılan yönlendirmelerin mutlaka ziyaret edilmesi gerekmektedir ve sonuçlar CRM kayıtlarına giriş yapılmalıdır.</li>
                      <li className="p-2"><b>8-)</b> Her bir arkadaşımız şirket düzeninin korunması adına belirtilen satış kurallarına uymak zorundadır.</li>
                      <li className="p-2"><b>9-)</b> Satış hangi temsilcinin üzerinde açılmış ise o temsilcinin üzerinde kalır, haklı bir gerekçe olmadığı takdirde satış asla değiştirilemez.</li>
                      <li className="p-2"><b>10-)</b> Üye paket süresi bitimine kadar asla yenileme için yetkili kişiler dışında aranmayacaktır. Saha ve diğer ekipler dahildir.</li>
                      <li className="p-2"><b>11-)</b> Üyeliği bitmiş yenilemelerde bölge farkı olmadan satış yapılabilir, <span className="text-danger">15 gün</span> kuralı geçerlidir.</li>
                      <li className="p-2"><b>12-)</b> Hediye ekspertizler Garantili Arabam olarak verilecektir.</li>
                      <li className="p-2"><b>13-)</b> Sahaya yönlendirme yapılan potansiyel üyenin adresi veya konum bilgisi verilmediği takdirde yönlendirme sayılmaz.</li>
                      <li className="p-2"><b>14-)</b> Yenilemelerde aylık olarak yenileme listesi dağıtılmadan yenileme yapılmayacaktır.</li>
                      <li className="p-2"><b>15-)</b> Sahaya gelen referanslarda satışlar referans gelen kişiye yazılır.</li>
                      <li className="p-2"><b>16-)</b> İçeriden gelen yönlendirmeler saha (Yeni Saha Satışı 2 Ay Kuralına Tabidir) dahil bölge sahibine yönlendirilir.</li>
                    </ul>

                    <h2 class="reminder">HATIRLATMA</h2>
                    <ul>
                      <li className="p-1">Yıllık izin kullanım yapıldığı aylarda aylık verilen hedeflerde hedef düşümü yapılmaz.</li>
                      <li className="p-1">İptal ve iadelerde iade edilen tutar satışlardan sorumlu olan kişinin bir sonraki ay alacak olduğu hedefine eklenir.</li>
                      <li className="p-1">İptal ve iadede müşteriye iade edilecek tutar promosyon, olta bayrak gönderimi, kullanmış olduğu Garantili Arabam ücretsiz ekspertizine, kredi girişleri veya kullandırımlarına ve kullandığı kredi kart durumuna bakılarak belirlenmektedir. Onay maili tarafıma atılması gerekmektedir.</li>
                      <li className="p-1">Şehir dışı seyahatlerde konaklamada şirketin ödeyeceği tutar için onay alınması gerekmektedir.</li>
                      <li className="p-1">Mesai dışı araç kullanımda onay alınması gerekmektedir.</li>
                      <li className="p-1">Yenileme görüşmeleri sonu olumsuz olduğunda mail yolu ile üyenin sayfasının açık kalmaması ve ilanlarının silinmesi için satış desteğe bilgi verilmesi gerekmektedir.</li>
                      <li className="p-1">Şirket personellerimiz şirketimizi temsil ettiğinden dolayı kılık kıyafetine, saç sakal tıraşı mutlaka özen göstermesi gerekmektedir.</li>
                      <li className="p-1">Herhangi bir mazeret kullanarak mesaisinin başında olamayacak arkadaşlarımız mutlaka mazeretini belirterek mazeret izin formu doldurup imzalayıp muhasebe tarafımıza iletmek zorundadır.</li>
                      <li className="p-1">SGK, 5510 sayılı Sosyal Sigortalar ve Genel Sağlık Sigortası Kanunu'nun 18. maddesi gereği, raporlu olunan 3 gün ve sonrası için rapor parası ödemesi yapıyor. Yasal olarak işverenin ilk iki gün için ödeme yapması zorunluluğu bulunmuyor.</li>
                    </ul>
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-orange" onClick={() => { this.props.onRequestClose(); setCookie("aggrements", 1) }}>Onaylıyorum</button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    )
  }
}
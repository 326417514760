import React from 'react';
import {useNavigate} from 'react-router-dom';
import AggrementsModal from '../component/home/AggrementsModal';
import { getCookie } from '../inc/cookies';

class HomeModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className='d-flex justify-content-center'>
        <h1>Anasayfa</h1>
        {getCookie("aggrements") === "0" && <AggrementsModal isOpen={this.state.isOpen} onRequestClose={() => this.setState({isOpen: false})} />}
      </div>
    );
  }
}

export default function Home() {
  return (
    <HomeModule navigate={useNavigate()} />
  )
}
import React from 'react';
import {useNavigate} from 'react-router-dom';

import swal from 'sweetalert';

import {setCookie} from '../inc/cookies';
import config from '../inc/config';


class LoginModule extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      username: '',
      password: ''
    };

    this.formOnSubmit = this.formOnSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0,0);
  }

  componentWillUnmount() {
  }

  formOnSubmit(event) {
    event.preventDefault();

    let infoMessage = '';

    if (!this.state.username) {
      infoMessage = 'Kullanıcı adı veya E-Posta adresi boş bırakılamaz.';
    } else if (!this.state.password) {
      infoMessage = 'Şifre boş bırakılamaz.';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Başarısız Kullanıcı Girişi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({username: this.state.username, password: this.state.password})
      };

      fetch(config.restApi + 'login', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200: {
            setCookie("sessionHash", responseData.memberInfo.sessionHash, 0);
            setCookie("aggrements", 0);
            this.props.navigate('/home');
          }
          break;
          default: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            }).then(() => this.setState({isLoading: false}));
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }

    return false;
  }

  render() {
    return (
      <div className="ibox login-page">
        <div className={this.state.isLoading ? "ibox-content sk-loading login-page" : "ibox-content"}>
          <div className="sk-spinner sk-spinner-fading-circle">
            <div className="sk-circle1 sk-circle"></div>
            <div className="sk-circle2 sk-circle"></div>
            <div className="sk-circle3 sk-circle"></div>
            <div className="sk-circle4 sk-circle"></div>
            <div className="sk-circle5 sk-circle"></div>
            <div className="sk-circle6 sk-circle"></div>
            <div className="sk-circle7 sk-circle"></div>
            <div className="sk-circle8 sk-circle"></div>
            <div className="sk-circle9 sk-circle"></div>
            <div className="sk-circle10 sk-circle"></div>
            <div className="sk-circle11 sk-circle"></div>
            <div className="sk-circle12 sk-circle"></div>
          </div>
          <div className="bg-white p-4 br-1">
            <form className="mt-4" onSubmit={this.formOnSubmit}>
              <div className="form-group">
                <label className="float-left">Kullanıcı adı veya E-posta</label>
                <input className="form-control form-input" onChange={event => this.setState({username: event.target.value})} placeholder="Kullanıcı adı veya E-posta" required={false} type="text" value={this.state.username} />
              </div>
              <div className="form-group">
                <label className="float-left">Şifre</label>
                <input className="form-control form-input" onChange={event => this.setState({password: event.target.value})} placeholder="Şifre" required={false} type="password" value={this.state.password} />
              </div>
              <button className="btn navbar-border text-white block full-width mb-4 mt-5" type="submit">Giriş</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default function Login() {
  return (
    <LoginModule navigate={useNavigate()} />
  )
}


import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import swal from "sweetalert";
import config from "../../inc/config";
import { getCookie } from "../../inc/cookies";
import { isoToDateTime, priceFormat } from "../../inc/helper";
import AppointmentCancelPaymentModal from "../../component/expertise/AppointmentCancelPaymentModal";
import AppointmentCancelModal from "../../component/expertise/AppointmentCancelModal";

class MultipleExpertiseDetailModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      appointmentDetail: {},
      open: false,
      openCancelAppointmentModal: false
    };
  }

  componentDidMount() {
    this.getMyAppointmentsDetail();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() { }

  getMyAppointmentsDetail() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + sessionHash }
    };

    fetch(`https://ucuzaekspertiz.com:2096/v1.0/appointment/${this.props.searchParams.get('id')}/tr`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200:
            this.setState({ isLoading: false, appointmentDetail: responseData.data })
            break;
          case 401:
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            }).then(this.props.navigate('/multipleExpertises'))
            break;
          default:
            this.setState({ isLoading: false });

            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
            break;
        }
      }, () => swal({ dangerMode: true, icon: "error", title: "Bağlantı Hatası", text: "Lütfen internet bağlantınızı kontrol ediniz.", buttons: "Yeniden Dene", }).then(() => this.getMyAppointmentsDetail()));
  }

  appointmentCancelPaymentOnClick(cancelledType, cancelledReason) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: { 'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json' },
      body: JSON.stringify({ cancelledType: cancelledType, cancelledReason: cancelledReason })
    };

    fetch(`https://ucuzaekspertiz.com:2096/v1.0/appointment/${this.props.searchParams.get('id')}/manualCancelPayment/tr`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200: {
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Ödeme başarıyla iptal edildi.',
              buttons: 'Tamam'
            }).then(() => this.getMyAppointmentsDetail(), this.setState({ open: false }));
          }
            break;
          default: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
            break;
        }
      }, () => swal({ dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam' }));
  }

  cancelAppointmentOnClick(cancelledReason, forceCancel) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: { 'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json' },
      body: JSON.stringify({ cancelledReason: cancelledReason, forceCancel: forceCancel })
    };

    fetch(`https://ucuzaekspertiz.com:2096/v1.0/appointment/${this.props.searchParams.get('id')}/manualCancelAppointment/tr`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200: {
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Randevu başarıyla iptal edildi.',
              buttons: 'Tamam'
            }).then(() => this.getMyAppointmentsDetail(), this.setState({ openCancelAppointmentModal: false }));
          }
            break;
          default: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
            break;
        }
      }, () => swal({ dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam' }));
  }

  appointmentStatus = (status) => {
    switch (status) {
      case "success":
        return { text: 'Başarılı', className: 'text-green' }; // Yeşil
      case "pending":
        return { text: 'Ödeme Bekleniyor', className: 'text-warning' }; // Sarı
      case "cancelled":
        return { text: 'İptal Edildi', className: 'text-danger' }; // Kırmızı
      case "cancelling":
        return { text: 'İptal Sürecinde', className: 'text-danger' }; // Kırmızı
      case "timeout":
      default:
        return { text: 'Ödeme Yapılmadı', className: 'text-danger' }; // Kırmızı
    }
  };

  paymentStatus = (status) => {
    switch (status) {
      case "standby":
        return { text: 'Ödeme Bekleniyor', className: 'text-warning' }; // Sarı
      case "processing":
        return { text: 'Devam Ediyor', className: 'text-warning' }; // Sarı
      case "success":
        return { text: 'Başarılı', className: 'text-green' }; // Yeşil
      case "failed":
        return { text: 'Başarısız', className: 'text-danger' }; // Yeşil
      case "cancelled":
        return { text: 'İptal Edildi', className: 'text-danger' }; // Kırmızı
      default:
        return { text: 'Beklemede', className: 'text-warning' }; // Kırmızı
    }
  };

  formatPlate = (plate) => {
    return plate.replace(/(\d{2})([a-zA-Z]+)(\d+)/, "$1 $2 $3").toUpperCase();
  };

  phoneFormatWithZero = (phone) => {
    let parsedValue = /^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(phone);
    return parsedValue ? parsedValue[1] + ' ' + '(' + parsedValue[2] + ') ' + parsedValue[3] + ' ' + parsedValue[4] + ' ' + parsedValue[5] : '-';
  }

  phoneFormat(phone) {
    let parsedValue = /^(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(phone);
    return parsedValue ? '(' + parsedValue[1] + ') ' + parsedValue[2] + ' ' + parsedValue[3] + ' ' + parsedValue[4] : '-';
  }

  render() {
    return this.state.isLoading ? (
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
    ) : (
      <div className="row">
        <div className="col">
          <div className="ibox">
            <div className="d-flex ibox-title text-black justify-content-between pr-2">
              <h4>Başvuru Bilgilerim</h4>
              <Link className="btn border btn-darkblue text-white" title="Geri" to={"/multipleExpertises"}> <i className="fa fa-reply"></i></Link>
            </div>
            <div className="ibox-content">
              <div className="row">
                <div className="col">
                  <h4 className="text-orange"><strong>RANDEVU</strong></h4>
                  <div className="hr-line-dashed mt-0"></div>
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Sağlayıcı Adı:</h4>
                        <span>{this.state.appointmentDetail?.provider?.name ? this.state.appointmentDetail?.provider?.name : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Randevu No:</h4>
                        <span>{this.state.appointmentDetail.id ? this.state.appointmentDetail.id : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Randevu Tarihi:</h4>
                        <span className="text-danger">{isoToDateTime(this.state.appointmentDetail.appointmentDate)}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Durum:</h4>
                        <span className={this.appointmentStatus(this.state.appointmentDetail?.appointmentStatus).className}>{this.appointmentStatus(this.state.appointmentDetail?.appointmentStatus).text}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Açıklama:</h4>
                        <span>
                          {this.state.appointmentDetail?.memberNote ? <span>{this.state.appointmentDetail?.memberNote}</span> : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Ödeme Durumu:</h4>
                        <span className={this.paymentStatus(this.state.appointmentDetail?.paymentTransaction?.paymentStatus).className}>{this.paymentStatus(this.state.appointmentDetail?.paymentTransaction?.paymentStatus).text}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <h4 className="text-orange"><strong>RANDEVU SAHİBİ</strong></h4>
                  <div className="hr-line-dashed mt-0"></div>
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Adı:</h4>
                        <span>{this.state.appointmentDetail.firstname ? this.state.appointmentDetail.firstname : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Soyadı:</h4>
                        <span>{this.state.appointmentDetail.lastname ? this.state.appointmentDetail.lastname : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">E-mail:</h4>
                        <span>{this.state.appointmentDetail.emailAddress ? this.state.appointmentDetail.emailAddress : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Telefon:</h4>
                        <span>{this.phoneFormat(this.state.appointmentDetail.phoneNumber)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <h4 className="text-orange"><strong>ARAÇ BİLGİLERİ</strong></h4>
                  <div className="hr-line-dashed mt-0"></div>
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Plaka:</h4>
                        <span>{this.state.appointmentDetail.vehiclePlate ? this.formatPlate(this.state.appointmentDetail.vehiclePlate) : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Marka:</h4>
                        <span>{this.state.appointmentDetail.vehicleBrand ? this.state.appointmentDetail.vehicleBrand : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Model:</h4>
                        <span>{this.state.appointmentDetail.vehicleModel ? this.state.appointmentDetail.vehicleModel : "-"}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <h4 className="text-orange"><strong>EKSPERTİZ BAYİ</strong></h4>
                  <div className="hr-line-dashed mt-0" />
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Adı:</h4>
                        <span>{this.state.appointmentDetail?.branch?.name ? this.state.appointmentDetail?.branch?.name : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Adres:</h4>
                        <span>{this.state.appointmentDetail?.branch?.address ? this.state.appointmentDetail?.branch?.address : "-"} {this.state.appointmentDetail?.branch?.cityName ? this.state.appointmentDetail?.branch?.cityName : "-"} / {this.state.appointmentDetail?.branch?.townName ? this.state.appointmentDetail?.branch?.townName : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Telefon:</h4>
                        <span>{this.phoneFormatWithZero(this.state.appointmentDetail?.branch?.phoneNumber)}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Email:</h4>
                        <span>{this.state.appointmentDetail?.branch?.emailAddress}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <h4 className="text-orange"><strong>EKSPERTİZ PAKETİ</strong></h4>
                  <div className="hr-line-dashed mt-0" />
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Paket Adı:</h4>
                        <span>{this.state.appointmentDetail?.package?.name ? this.state.appointmentDetail?.package?.name : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Toplam:</h4>
                        <span>{priceFormat(this.state.appointmentDetail.finalPrice)} ₺</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Açıklama:</h4>
                        <span>{this.state.appointmentDetail.memberNote ? this.state.appointmentDetail.memberNote : "-"}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <h4 className="text-orange"><strong>DİĞER BİLGİLER</strong></h4>
                  <div className="hr-line-dashed mt-0" />
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <span>Bu randevu {isoToDateTime(this.state.appointmentDetail.addedDate)} tarihinde oluşturulmuştur.</span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    {this.state.appointmentDetail?.paymentTransaction?.paymentStatus === 'success' && <button className="btn border btn-orange text-white mr-2" onClick={() => this.setState({ open: true })} type="button">Ödemeyi iptal et</button>}
                    {this.state.appointmentDetail?.integrationStatus === "success" && <button className="btn border btn-orange text-white mr-2" onClick={() => this.setState({ openCancelAppointmentModal: true })} type="button">Randevuyu iptal et</button>}
                  </div>
                </div>
              </div>
              <AppointmentCancelPaymentModal isOpen={this.state.open} onRequestClose={() => this.setState({ open: false })} saveOnClick={(cancelledType, cancelledReason) => this.appointmentCancelPaymentOnClick(cancelledType, cancelledReason)} />
              <AppointmentCancelModal isOpen={this.state.openCancelAppointmentModal} onRequestClose={() => this.setState({ openCancelAppointmentModal: false })} saveOnClick={(cancelledReason, forceCancel) => this.cancelAppointmentOnClick(cancelledReason, forceCancel)} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default function MultipleExpertiseDetail() {
  return (
    <MultipleExpertiseDetailModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  );
}

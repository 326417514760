import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import swal from 'sweetalert';

import ConnectCategoryFieldModal from '../../component/category/categoryField/ConnectCategoryFieldModal';
import UpdateCategoryFieldRequiredModal from '../../component/category/categoryField/UpdateCategoryFieldRequiredModal';
import ConnectCategoryAttributeModal from '../../component/category/categoryAttribute/ConnectCategoryAttributeModal';
import UpdateCategoryAttributeRequiredModal from '../../component/category/categoryAttribute/UpdateCategoryAttributeRequiredModal';

import config from '../../inc/config';
import {getCookie} from '../../inc/cookies';
import AddedCategoryLanguageModal from '../../component/category/categoryAttribute/AddedCategoryLanguageModal';

let categories = [];
let selectedCategoryId = null;

class CategoryModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      isOpenConnectCategoryFieldModal: false,
      isOpenUpdateCategoryFieldRequiredModal: false,
      isOpenConnectCategoryAttributeModal: false,
      isOpenUpdateCategoryAttributeRequiredModal: false,

      categories: categories,
      categoryAttributes: [],
      categoryFields: [],

      categoryFieldInfo : {},

      categoryFieldTableId: 0,
      categoryFieldListed: 0,
      categoryFieldRequired: 0,
      categoryFieldSort: 0,
      categoryAttributeTableId: 0,
      categoryAttributeListed: 0,
      categoryAttributeRequired: 0,
      selectedCategoryId: selectedCategoryId,
      selectedCategoryName: '',

      
      isOpenAddedCategoryAttributeLanguageModal: false,

      name: '',
      canonicalName: '',
      canonicalUrl: '',
      content: '',
      metaTitle: '',
      metaDescription: '',
      language: '',
      categoryLanguages: [],

      attributeLanguageId: 0,
      attributeLanguages: [],
      editCategoryAttributeLanguages: null,

      selectedCategories: []
    };
  }

  componentDidMount() {
    if (categories.length === 0) {
      this.getCategories(0, 0, '');
      this.getLanguages(0)
    } else {
      this.getAttributes(this.state.selectedCategoryId);
      this.getFields(this.state.selectedCategoryId);
      this.getLanguages(this.state.selectedCategoryId)
    }
    this.getCategories(0, 0, '');
    window.scrollTo(0, 0);
  }

  getCategories(categoryId, depth, categoryName) {
    this.selectedCategories(categoryId, depth, categoryName);

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'categories/tr/' + categoryId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          let categories = this.state.categories;

          while (depth < categories.length) {
            categories.pop();
          }

          categories[depth] = responseData.categories;
          this.setState({categories: categories});
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/home'))
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  selectedCategories(categoryId, depth, categoryName) {
    let categories = this.state.categories;

    while (depth < categories.length) {
      categories.pop();
    }
    const selectedCategories = this.state.selectedCategories.slice(0, depth);
    selectedCategories.push({categoryId, categoryName});
    this.setState({categories: categories, selectedCategoryId: categoryId, selectedCategoryName: categoryName, selectedCategories: selectedCategories, isShowContinueButton: false});
    this.getAttributes(categoryId);
    this.getFields(categoryId);
  }

  // selectedCategories(categoryId, categoryName) {
  //   let selectedCategories = [...this.state.selectedCategories];
  //   this.setState({selectedCategoryId: categoryId, selectedCategoryName: categoryName, selectedCategories: selectedCategories});
  //   this.getAttributes(categoryId);
  //   this.getFields(categoryId);
  // }

  getAttributes(categoryId) {

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'categoryAttributes/tr/' + categoryId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({isLoading: false, categoryAttributes: responseData.categoryAttributes});
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/home'))
        break;
        default:
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getFields(categoryId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'categoryFields/tr/' + categoryId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({isLoading: false, categoryFields: responseData.categoryFields});
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/home'))
        break;
        default:
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));

  }

  getLanguages(categoryId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'categoryLanguages/tr/' + categoryId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({isLoading: false, categoryLanguages: responseData.categoryLanguages});
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/home'))
        break;
        default:
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));

  }

  saveCategoryLanguageOnClick(categoryLanguageInfo, id) { 

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({name: categoryLanguageInfo.name, canonicalName: categoryLanguageInfo.canonicalName, canonicalUrl: categoryLanguageInfo.canonicalUrl, content: categoryLanguageInfo.content, metaTitle: categoryLanguageInfo.metaTitle, metaDescription: categoryLanguageInfo.metaDescription, language: categoryLanguageInfo.language})
    };

    fetch(config.restApi + 'categoryLanguage/tr/' + id, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Özelliğe ait çeviri başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.getLanguages(this.state.selectedCategoryId), this.setState({isOpenAddedCategoryAttributeLanguageModal: false}), categoryLanguageInfo.name = '', categoryLanguageInfo.canonicalName = '', categoryLanguageInfo.canonicalUrl = '', categoryLanguageInfo.content = '', categoryLanguageInfo.metaTitle = '', categoryLanguageInfo.metaDescription = '', categoryLanguageInfo.language = '');
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  updateCategoryAttributeLanguageOnClick(categoryLanguageInfo, id) {
    
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify(categoryLanguageInfo)
    };

    fetch(config.restApi + 'categoryLanguage/tr/' + id, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Çeviri başarıyla güncellendi.',
            buttons: 'Tamam'
          }).then(() => this.getLanguages(this.state.selectedCategoryId), this.setState({isOpenAddedCategoryAttributeLanguageModal: false, editCategoryAttributeLanguages: categoryLanguageInfo}));
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteCategoryAttributeLanguageOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Çeviriyi silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'categoryLanguage/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Çeviri başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getLanguages(this.state.selectedCategoryId));
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  connectCategoryFieldOnClick(fieldInfo) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({fieldId: fieldInfo.fieldId, categoryId: fieldInfo.categoryId, listed: fieldInfo.listed, sort: parseInt(fieldInfo.sort), required: fieldInfo.required})
    };

    fetch(config.restApi + 'categoryField/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Kategoriye ait özel alan başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.getFields(this.state.selectedCategoryId), this.setState({isOpenConnectCategoryFieldModal: false}), fieldInfo.required = 0);
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  updateFieldRequiredOnClick(listed, required, sort) {

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({listed: listed, required: required, sort: parseInt(sort)})
    };

    fetch(config.restApi + 'categoryField/tr/' + this.state.categoryFieldTableId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Zorunluluk durumu başarıyla güncellendi.',
            buttons: 'Tamam'
          }).then(() => this.getFields(this.state.selectedCategoryId), this.setState({isOpenUpdateCategoryFieldRequiredModal: false, categoryFieldListed: listed, categoryFieldRequired: required, categoryFieldSort: sort}));
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteCategoryFieldOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Özel alanı silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'categoryField/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200:
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Özel alan başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getFields(this.state.selectedCategoryId));
            break;
            default:
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  connectCategoryAttributeOnClick(attributeInfo) {

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({attributeId: attributeInfo.attributeId, categoryId: attributeInfo.categoryId, listed: attributeInfo.listed, required: attributeInfo.required})
    };

    fetch(config.restApi + 'categoryAttribute/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Kategoriye ait özellik başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.getAttributes(this.state.selectedCategoryId), this.setState({isOpenConnectCategoryAttributeModal: false}), attributeInfo.listed = 0, attributeInfo.required = 0);
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  updateAttributeRequiredOnClick(listed, required) {

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({listed: listed, required: required})
    };

    fetch(config.restApi + 'categoryAttribute/tr/' + this.state.categoryAttributeTableId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Zorunluluk durumu başarıyla güncellendi.',
            buttons: 'Tamam'
          }).then(() => this.getAttributes(this.state.selectedCategoryId), this.setState({isOpenUpdateCategoryAttributeRequiredModal: false, categoryAttributeListed: listed, categoryAttributeRequired: required}));
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteCategoryAttributeOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Özelliği silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'categoryAttribute/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200:
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Özellik başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getAttributes(this.state.selectedCategoryId));
            break;
            default:
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  scrollOffset = () => {
    setTimeout(() => {
      const element = document.getElementById("offset");
      if (element) {
        element.scrollLeft += 300;
      }
    }, 200);
  }

  render() {
    return this.state.isLoading ?
    <div className="ibox-content sk-loading position-static">
    <div className="sk-spinner sk-spinner-fading-circle">
      <div className="sk-circle1 sk-circle"></div>
      <div className="sk-circle2 sk-circle"></div>
      <div className="sk-circle3 sk-circle"></div>
      <div className="sk-circle4 sk-circle"></div>
      <div className="sk-circle5 sk-circle"></div>
      <div className="sk-circle6 sk-circle"></div>
      <div className="sk-circle7 sk-circle"></div>
      <div className="sk-circle8 sk-circle"></div>
      <div className="sk-circle9 sk-circle"></div>
      <div className="sk-circle10 sk-circle"></div>
      <div className="sk-circle11 sk-circle"></div>
      <div className="sk-circle12 sk-circle"></div>
    </div>
  </div>
  :
  <>
  <div className="row">
    <div className="col">
      <div className="ibox">
        <div className="ibox-title d-flex justify-content-between text-darkblue pr-2">
          <h5>Kategori Listesi</h5>
        </div>
        <div className="ibox-content">
          <div className="row mb-2">
            <div className="col">
              <ul className="d-flex list-unstyled" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                {this.state.selectedCategories.map((category, index) => (
                  category.categoryId > 0 || index > 0 ? 
                  <li key={index} className="breadcrumb-item">
                    <a className="text-orange" href="#" >
                      {category.categoryName}
                    </a>
                  </li>
                  : null
                ))}
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="ml-sm-0">
                <div className="row overflow-auto mb-3" id='offset' onClick={this.scrollOffset()}>
                  <div className="row col-12 d-flex flex-nowrap pl-4" >
                    {this.state.categories.map((categoryList, depthIndex) => (
                      <div className="col-6 col-sm-3" key={depthIndex}>
                        <ul className="list-group text-center">
                          {categoryList.map((categoryInfo, categoryIndex) => (
                            <li
                              className="list-group-item p-2 pointer"
                              style={{
                                backgroundColor: this.state.selectedCategories.some(item => item.categoryId !== '' && item.categoryId === categoryInfo.id) ? '#e04403' : '',
                                color: this.state.selectedCategories.some(item => item.categoryId !== '' && item.categoryId === categoryInfo.id) ? 'white' : ''
                              }}
                              key={depthIndex + '-' + categoryIndex} 
                              onClick={() => {
                                if (categoryInfo.hasChild) {
                                  this.getCategories(categoryInfo.id, depthIndex + 1, categoryInfo.name)
                                  this.getLanguages(categoryInfo.id)
                                } else {
                                  this.selectedCategories(categoryInfo.id, depthIndex + 1, categoryInfo.name);
                                  this.getLanguages(categoryInfo.id)
                                }
                              }}>
                              {categoryInfo.name}
                              {categoryInfo.hasChild ? <i className="fa fa-angle-right float-right mt-1"></i> : null}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            this.state.selectedCategoryId !== 0 ?
            <>
              <div className="row">
                <div className="col">
                  <div className="d-flex justify-content-between">
                    <h4 className="text-darkblue">Kategori Bilgileri</h4>
                    {/* <button className="btn btn-orange" onClick={() => this.setState({editCategoryAttributeLanguages: null, isOpenAddedCategoryAttributeLanguageModal: true})} title="Dil Ekle"><i className="fa fa-plus"></i></button> */}
                  </div>
                  <div className="hr-line-dashed"></div>
                  <div className="row">
                    <div className="col">
                    <div className="overflow-auto">
                      <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="text-center text-darkblue">
                          <th>Kategori Adı</th>
                          <th>Dil</th>
                          <th>Meta Başlık</th>
                          <th>Meta Açıklama</th>
                          <th>Anahtar Kelimeler</th>
                          <th>Durum</th>
                          <th>İşlem</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {this.state.categoryLanguages.map((categoryLanguageInfo) =>
                          <tr key={categoryLanguageInfo.id}>
                            <td className="align-middle text-orange">{categoryLanguageInfo.name}</td>
                            <td className="align-middle">{categoryLanguageInfo.language}</td>
                            <td className="align-middle">{categoryLanguageInfo.metaTitle ? categoryLanguageInfo.metaTitle : '-'}</td>
                            <td className="align-middle">{categoryLanguageInfo.metaDescription ? categoryLanguageInfo.metaDescription : '-'}</td>
                            <td className="align-middle">{categoryLanguageInfo.keywords ? categoryLanguageInfo.keywords : '-'}</td>
                            <td className="align-middle">{categoryLanguageInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                            <td className="align-middle" style={{minWidth: 98.5}}>
                              <button className="btn btn-outline btn-orange text-center m-1" onClick={() => this.setState({isOpenAddedCategoryAttributeLanguageModal: true, attributeLanguageId: categoryLanguageInfo.id, editCategoryAttributeLanguages: categoryLanguageInfo})} title="Düzenle"><i className="fa fa-pencil text-white action-icon"></i></button>
                              <button className="btn btn-outline btn-darkblue" onClick={() => this.deleteCategoryAttributeLanguageOnClick(categoryLanguageInfo.id)} title="Sil"><i className="fa fa-trash"></i></button>
                            </td>
                          </tr>
                        )}
                        {this.state.categoryLanguages.length === 0 ?
                          <tr>
                            <td className="align-middle p-3" colSpan="7">Çeviri Listesi Bulunamadı !</td>
                          </tr>
                        : null}
                      </tbody> 
                      </table>
                    </div>
                    </div>
                  </div>
                  {/* <AddedCategoryLanguageModal isOpen={this.state.isOpenAddedCategoryAttributeLanguageModal} modalTitle="Çeviri Ekle" modalDescription="Kategoriye ait çeviri eklemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({editCategoryAttributeLanguages: null, isOpenAddedCategoryAttributeLanguageModal: false})} saveOnClick={categoryLanguageInfo => this.saveCategoryLanguageOnClick(categoryLanguageInfo, this.state.selectedCategoryId)} /> */}
                  {this.state.editCategoryAttributeLanguages ?
                  <AddedCategoryLanguageModal buttonRef={this.state.buttonRef} defaultValue={this.state.editCategoryAttributeLanguages} isOpen={this.state.isOpenAddedCategoryAttributeLanguageModal} modalTitle="Çeviri Düzenle" modalDescription="Çeviri bilgisini düzenlemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({editCategoryAttributeLanguages: null, isOpenAddedCategoryAttributeLanguageModal: false})} saveOnClick={categoryLanguageInfo => this.updateCategoryAttributeLanguageOnClick(categoryLanguageInfo, this.state.attributeLanguageId)} />
                  : null}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex justify-content-between">
                    <h4 className="text-darkblue"><b>Özel Alan Listesi</b></h4>
                    {this.state.selectedCategoryId !== 0 && <button className="btn btn-orange" onClick={() => this.setState({isOpenConnectCategoryFieldModal: true})} title="Özel Alan Ekle"><i className="fa fa-plus"></i></button>}
                  </div>
                  <div className="hr-line-dashed"></div>
                  <div className="overflow-auto">
                    <table className="table table-striped table-bordered">
                    <thead>
                      <tr className="text-center text-darkblue">
                        <th>Alan Adı</th>
                        <th>Alan Tipi</th>
                        <th>Giriş Tipi</th>
                        <th>Veri Tipi</th>
                        <th>Açıklama</th>
                        <th>Sıralama</th>
                        <th>Liste Öğesi</th>
                        <th>Zorunluluk</th>
                        <th>Durum</th>
                        <th>İşlem</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                    {this.state.categoryFields.map((categoryFieldInfo) =>
                      <tr key={categoryFieldInfo.id}>
                        <td className="align-middle">{categoryFieldInfo.name}</td>
                        <td className="align-middle">{categoryFieldInfo.widgetType}</td>
                        <td className="align-middle">{categoryFieldInfo.inputType}</td>
                        <td className="align-middle">{categoryFieldInfo.dataType}</td>
                        <td className="align-middle ">{categoryFieldInfo.description}</td>
                        <td className="align-middle">{categoryFieldInfo.sort}</td>
                        <td className="align-middle">{categoryFieldInfo.listed === 1 ? <i className="fa fa-check text-green pointer" title="Liste Öğesi"></i> : <i className="fa fa-times text-orange pointer" title="Liste Öğesi Değil"></i>}</td>
                        <td className="align-middle">{categoryFieldInfo.required === 1 ? <i className="fa fa-check text-green pointer" title="Zorunlu"></i> : <i className="fa fa-times text-orange pointer" title="Zorunlu Değil"></i>}</td>
                        <td className="align-middle">{categoryFieldInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                        <td className="align-middle" style={{minWidth:98.5}}>
                          <button className="btn btn-outline btn-orange text-center m-1" onClick={() => this.setState({isOpenUpdateCategoryFieldRequiredModal: true, categoryFieldTableId: categoryFieldInfo.id, categoryFieldListed: categoryFieldInfo.listed, categoryFieldRequired: categoryFieldInfo.required, categoryFieldSort: categoryFieldInfo.sort})} title="Düzenle"><i className="fa fa-pencil text-white action-icon"></i></button>
                          <button className="btn btn-outline btn-darkblue" onClick={() => this.deleteCategoryFieldOnClick(categoryFieldInfo.id)} title="Sil"><i className="fa fa-trash"></i></button>
                        </td>
                      </tr>
                    )}
                    {this.state.categoryFields.length === 0 ?
                      <tr>
                        <td className="align-middle p-3" colSpan="10">Özel Alan Bulunamadı !</td>
                      </tr>
                    : null}
                    </tbody>
                    </table>
                    <ConnectCategoryFieldModal categoryId={this.state.selectedCategoryId} isOpen={this.state.isOpenConnectCategoryFieldModal} modalTitle={`${this.state.selectedCategoryName} Kategorisine Özel Alan Bağla`} modalDescription="İlgili kategoriye ait özel alan eklemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({isOpenConnectCategoryFieldModal: false})} saveOnClick={fieldInfo => this.connectCategoryFieldOnClick(fieldInfo)} />
                    <UpdateCategoryFieldRequiredModal isOpen={this.state.isOpenUpdateCategoryFieldRequiredModal} modalTitle="Zorunluluk Durumu" modalDescription="İlgili kategoriye ait zorunluluk durumunu güncellemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({isOpenUpdateCategoryFieldRequiredModal: false})} categoryFieldListed={this.state.categoryFieldListed} categoryFieldRequired={this.state.categoryFieldRequired} categoryFieldSort={this.state.categoryFieldSort} saveOnClick={(listed, required, sort) => this.updateFieldRequiredOnClick(listed, required, sort)}/>
                  </div>
                </div>
              </div>
              {this.state.categoryAttributes.map((categoryAttributeInfo) =>
                <div className="row" key={categoryAttributeInfo.id}>
                  <div className="col">
                    <div className="d-flex justify-content-between mt-3">
                      <h4 className="text-darkblue"><b>Özellikler Listesi</b></h4>
                    </div>
                    <div className="hr-line-dashed my-1"></div>
                    <div className="d-flex justify-content-between">
                      <h4 className="text-darkblue">{categoryAttributeInfo.name}</h4>
                      {this.state.selectedCategoryId !== 0 && <button className="btn btn-orange" onClick={() => this.setState({isOpenConnectCategoryAttributeModal: true})} title="Özellik Ekle"><i className="fa fa-plus"></i></button>}
                    </div>
                    <div className="hr-line-dashed"></div>
                      <div className="overflow-auto">
                        <table className="table table-striped table-bordered">
                        <thead>
                          <tr className="text-center text-darkblue">
                            <th>Özellik Adı</th>
                            <th>Açıklama</th>
                            <th>Sıralama</th>
                            <th>Liste Öğesi</th>
                            <th>Zorunluluk</th>
                            <th>Durum</th>
                            <th>İşlem</th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {categoryAttributeInfo.items.map((itemInfo) =>
                            <tr key={itemInfo.id}>
                              <td className="align-middle">{itemInfo.name}</td>
                              <td className="align-middle">{itemInfo.description}</td>
                              <td className="align-middle">{itemInfo.sort}</td>
                              <td className="align-middle">{itemInfo.listed === 1 ? <i className="fa fa-check text-green pointer" title="Liste Öğesi"></i> : <i className="fa fa-times text-orange pointer" title="Liste Öğesi Değil"></i>}</td>
                              <td className="align-middle">{itemInfo.required === 1 ? <i className="fa fa-check text-green pointer" title="Zorunlu"></i> : <i className="fa fa-times text-orange pointer" title="Zorunlu Değil"></i>}</td>
                              <td className="align-middle">{itemInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                              <td className="align-middle" style={{minWidth:98.5}}>
                                <button className="btn btn-outline btn-orange text-center m-1" onClick={() => this.setState({isOpenUpdateCategoryAttributeRequiredModal: true, categoryAttributeTableId: itemInfo.id, categoryAttributeRequired: itemInfo.required})} title="Düzenle"><i className="fa fa-pencil text-white action-icon"></i></button>
                                <button className="btn btn-outline btn-darkblue" onClick={() => this.deleteCategoryAttributeOnClick(itemInfo.id)} title="Sil"><i className="fa fa-trash"></i></button>
                              </td>
                            </tr>
                          )}
                          {categoryAttributeInfo.items.length === 0 ?
                            <tr>
                              <td className="align-middle p-3" colSpan="6">Özellik Bulunamadı !</td>
                            </tr>
                          : null}
                        </tbody>
                        </table>
                      </div>
                  </div>
                </div>
              )}
              {this.state.categoryAttributes.length === 0 &&
                <div className="row">
                  <div className="col">
                    <div className="d-flex justify-content-between">
                      <h4 className="text-darkblue">Özellik Listesi</h4>
                      {this.state.selectedCategoryId !== 0 && <button className="btn btn-orange" onClick={() => this.setState({isOpenConnectCategoryAttributeModal: true})} title="Özellik Ekle"><i className="fa fa-plus"></i></button>}
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="overflow-auto">
                      <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="text-center text-darkblue">
                          <th>Özellik Adı</th>
                          <th>Açıklama</th>
                          <th>Sıralama</th>
                          <th>Zorunluluk</th>
                          <th>Durum</th>
                          <th>İşlem</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        <tr>
                          <td className="align-middle p-3" colSpan="6">Özellik Bulunamadı !</td>
                        </tr>
                      </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              }
              <ConnectCategoryAttributeModal categoryId={this.state.selectedCategoryId} isOpen={this.state.isOpenConnectCategoryAttributeModal} modalTitle={`${this.state.selectedCategoryName} Kategorisine Özellik Bağla`}modalDescription="İlgili kategoriye ait özellik eklemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({isOpenConnectCategoryAttributeModal: false})} saveOnClick={attributeInfo => this.connectCategoryAttributeOnClick(attributeInfo)} />
              <UpdateCategoryAttributeRequiredModal isOpen={this.state.isOpenUpdateCategoryAttributeRequiredModal} modalTitle="Zorunluluk Durumu" modalDescription="İlgili kategoriye ait özellik zorunluluk durumunu güncellemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({isOpenUpdateCategoryAttributeRequiredModal: false})} categoryAttributeListed={this.state.categoryAttributeListed} categoryAttributeRequired={this.state.categoryAttributeRequired} saveOnClick={(listed, required) => this.updateAttributeRequiredOnClick(listed, required)}/>
            </>
            :
            <>
              <div className="row">
                <div className="col">
                  <div className="d-flex justify-content-between">
                    <h4 className="text-darkblue">Kategori Bilgileri</h4>
                  </div>
                  <div className="hr-line-dashed"></div>
                  <div className="row">
                    <div className="col">
                    <div className="overflow-auto">
                      <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="text-center text-darkblue">
                          <th>Kategori Adı</th>
                          <th>Dil</th>
                          <th>Meta Başlık</th>
                          <th>Meta Açıklama</th>
                          <th>Anahtar Kelimeler</th>
                          <th>Durum</th>
                          <th>İşlem</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        <tr>
                          <td className="align-middle p-3" colSpan="7">Kategoriye ait çeviri eklemek için kategori seçimi yaparak devam edebilirsiniz.</td>
                        </tr>
                      </tbody>
                      </table>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex justify-content-between">
                    <h4 className="text-darkblue"><b>Özel Alan Listesi</b></h4>
                  </div>
                  <div className="hr-line-dashed"></div>
                  <div className="overflow-auto">
                    <table className="table table-striped table-bordered">
                    <thead>
                      <tr className="text-center text-darkblue">
                        <th>Alan Adı</th>
                        <th>Alan Tipi</th>
                        <th>Giriş Tipi</th>
                        <th>Veri Tipi</th>
                        <th>Açıklama</th>
                        <th>Sıralama</th>
                        <th>Zorunluluk</th>
                        <th>Durum</th>
                        <th>İşlem</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      <tr>
                        <td className="align-middle p-3" colSpan="9">Kategoriye ait özel alan eklemek için kategori seçimi yaparak devam edebilirsiniz.</td>
                      </tr>
                    </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="d-flex justify-content-between">
                    <h4 className="text-darkblue"><b>Özellikler Listesi</b></h4>
                  </div>
                  <div className="hr-line-dashed"></div>
                    <div className="overflow-auto">
                      <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="text-center text-darkblue">
                          <th>Özellik Adı</th>
                          <th>Grup Adı</th>
                          <th>Açıklama</th>
                          <th>Sıralama</th>
                          <th>Durum</th>
                          <th>İşlem</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        <tr>
                          <td className="align-middle p-3" colSpan="6">Kategoriye ait özellik eklemek için kategori seçimi yaparak devam edebilirsiniz.</td>
                        </tr>
                      </tbody>
                      </table>
                    </div>
                </div>
              </div>
            </>
          }

        </div>
      </div>
    </div>
  </div>
  </>
  }
}

export default function Categories() {
  return (
    <CategoryModule navigate={useNavigate()} />
  )
}

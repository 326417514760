import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import MemberWorkItemAddModal from '../../../component/memberWorkItemModals/MemberWorkItemAddModal';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';
import {isoToDateTime} from '../../../inc/helper';
import ReactPaginate from 'react-paginate';

class MemberWorkItemDetailModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenMemberWorkItemAddModal: false,

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 50,

      member: {},
      memberWorkItems: []
    };

    this.onCompleted = this.onCompleted.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getMemberDetails(this.state.currentPage);
  }

  componentWillUnmount() {
  }

  getMemberDetails(currentPage) {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(this.props.navigate('/home'));
        }else{
          this.setState({isLoading: false});
        }
      }
    }

    fetch(config.restApi + 'member/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, member: responseData.member});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message);
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + `memberWorkItems/tr/`+ this.props.searchParams.get('id') + `?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentMemberWorkItems = responseData.memberWorkItems.slice(startIndex, endIndex);
          this.setState({isLoading: false, memberWorkItems: currentMemberWorkItems, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  onCompleted() {
    this.getMemberDetails(this.state.currentPage);
    this.setState({isOpenMemberWorkItemAddModal: false});
  }

  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.getMemberDetails(this.state.currentPage))
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>

        <MemberWorkItemAddModal isOpen={this.state.isOpenMemberWorkItemAddModal} memberId={this.props.searchParams.get('id')} modalDescription="Üyeye zimmet eklemek için aşağıdaki alanları doldurunuz !" modalTitle="Zimmet Ekle" onCompleted={this.onCompleted} onRequestClose={() => this.setState({isOpenMemberWorkItemAddModal: false})} />
        <div className="row">
          <div className="col">
            <div className="ibox">
              <div className="ibox-title text-darkblue d-flex justify-content-between pr-2">
                <h5><span className="text-orange">{this.state.member.firstname} {this.state.member.lastname}</span> Kişisine Ait Zimmet Listesi</h5>
                <div className="d-flex">
                  <button className="btn btn-orange mr-2" onClick={() => this.setState({isOpenMemberWorkItemAddModal: true})} title="Ekle"><i className="fa fa-plus"></i> Zimmet Ekle</button>
                  <button className="btn btn-darkblue text-white" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i></button>
                </div>
              </div>
              <div className="table-responsive overflow-x-auto">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="text-center text-darkblue">
                      <th>Ürün Adı</th>
                      <th>Teslim Edilme Tarihi</th>
                      <th>Teslim Edilme Notu</th>
                      <th>Teslim Alma Tarihi</th>
                      <th>Teslim Alma Notu</th>
                      <th>Durum</th>
                      <th>İşlem</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {this.state.memberWorkItems.map(memberWorkItemInfo =>
                      <tr key={memberWorkItemInfo.id}>
                        <td className="align-middle"><Link to={"/workItemMembers?id=" + memberWorkItemInfo.workItemId}><strong>{memberWorkItemInfo.workItemName}</strong></Link></td>
                        <td className="align-middle">{isoToDateTime(memberWorkItemInfo.deliveryDate)}</td>
                        <td className="align-middle">{memberWorkItemInfo.deliveryNote ? memberWorkItemInfo.deliveryNote : '-'}</td>
                        <td className="align-middle">{memberWorkItemInfo.returnDate ? isoToDateTime(memberWorkItemInfo.returnDate) : <span className="text-orange">Henüz teslim alınmadı !</span>}</td>
                        <td className="align-middle">{memberWorkItemInfo.returnNote ? memberWorkItemInfo.returnNote : '-'}</td>
                        <td className="align-middle">{memberWorkItemInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                        <td className="align-middle">
                          <Link className="btn btn-orange text-center" to={"/memberWorkItemEdit?id=" + memberWorkItemInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link>
                        </td>
                      </tr>
                    )}
                    {this.state.memberWorkItems.length === 0 ?
                      <tr>
                        <td className="align-middle p-3" colSpan="7"><span className="text-orange">{this.state.member.firstname} {this.state.member.lastname}</span> Kişisine Ait Zimmet Bulunamadı !</td>
                      </tr>
                    : null}
                  </tbody>
                </table>
                {
                this.state.memberWorkItems.length !== 0 &&
                <div className="d-flex justify-content-sm-end justify-content-start pl-1 pl-sm-0">
                  <ReactPaginate
                  previousLabel={"Geri"}
                  nextLabel={"İleri"}
                  breakLabel={"..."}  
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={1}
                  onPageChange={page => this.handlePageClick(page)}
                  pageRangeDisplayed={10}
                  containerClassName={'pagination'}
                  activeClassName={'activePage'}
                  forcePage={this.state.currentPage - 1}
                  />
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </> 
  }
}

export default function MemberWorkItemDetail() {
  return (
    <MemberWorkItemDetailModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}
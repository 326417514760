import React from 'react';
import Modal from 'react-modal';
import swal from 'sweetalert';

import config from '../../../inc/config';

export default class AddedCategoryLanguageModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCompleted: true,
      enterPressed: false,

      categoryLanguageInfo : {
        name: this.props.defaultValue && this.props.defaultValue.name || '',
        canonicalName: this.props.defaultValue && this.props.defaultValue.canonicalName || '',
        canonicalUrl: this.props.defaultValue && this.props.defaultValue.canonicalUrl || '',
        content: this.props.defaultValue && this.props.defaultValue.content || '',
        keywords: this.props.defaultValue && this.props.defaultValue.keywords || '',
        metaTitle: this.props.defaultValue && this.props.defaultValue.metaTitle || '',
        metaDescription: this.props.defaultValue && this.props.defaultValue.metaDescription || '',
        language: this.props.defaultValue && this.props.defaultValue.language || ''
      },

      languageList: config.languages,
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({
        categoryLanguageInfo: {
          name: this.props.defaultValue.name || '',
          canonicalName: this.props.defaultValue.canonicalName || '',
          canonicalUrl: this.props.defaultValue.canonicalUrl || '',
          content: this.props.defaultValue.content || '',
          keywords: this.props.defaultValue.keywords || '',
          metaTitle: this.props.defaultValue.metaTitle || '',
          metaDescription: this.props.defaultValue.metaDescription || '',
          language: this.props.defaultValue.language || ''
        }
      });
    }
  }  

  saveOnClick() {
    let infoMessage = '';

    if(!this.state.categoryLanguageInfo.name) {
      infoMessage = "Kategori adı boş geçilemez !";
    } else if(!this.state.categoryLanguageInfo.canonicalName) {
      infoMessage = "Standart isim seçeneği boş geçilemez !";
    } else if(!this.state.categoryLanguageInfo.canonicalUrl) {
      infoMessage = "Standart url seçeneği boş geçilemez !";
    } else if(!this.state.categoryLanguageInfo.language) {
      infoMessage = "Dil seçeneği boş geçilemez !";
    }   

    if(infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.props.saveOnClick(this.state.categoryLanguageInfo);
    }
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter') {
      this.saveOnClick();
      event.preventDefault();
    }
  }
  
  render() {
    return (
      <div className="modal inmodal">
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={{
              content : {
                position : 'null',
                top : 'null',
                left : 'null',
                right : 'null',
                bottom : 'null',
                border : 'none',
                background : '#fff',
                padding : 'null',
                maxWidth : '50%',
                minWidth : '350px'
              },
              overlay : {
                position : 'fixed',
                top : 0,
                left : 0,
                right : 0,
                bottom : 0,
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                backgroundColor : 'rgba(0,0,0,00.1)',
                zIndex : 2500
              }
            }}
          >
            <div className={this.state.isCompleted ? "ibox-content no-padding" : "ibox-content sk-loading no-padding"}>
              <div className="sk-spinner sk-spinner-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
              <div className="modal-header d-block text-center text-darkblue">
                <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
                <h4 className="modal-title">{this.props.modalTitle}</h4>
                <h5 className="font-normal">{this.props.modalDescription}</h5>
              </div>
              <div className="modal-body text-darkblue">
                <strong>Kategori Adı<strong className="text-danger ml-1">*</strong></strong>
                <input className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({categoryLanguageInfo: {...this.state.categoryLanguageInfo, name: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Kategori Adı" readOnly type="text" value={this.state.categoryLanguageInfo.name}/> 
								<strong>Standart Adı<strong className="text-danger ml-1">*</strong></strong>
                <input className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({categoryLanguageInfo: {...this.state.categoryLanguageInfo, canonicalName: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Standart Adı" readOnly type="text" value={this.state.categoryLanguageInfo.canonicalName}/> 
								<strong>Standart Url<strong className="text-danger ml-1">*</strong></strong>
                <input className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({categoryLanguageInfo: {...this.state.categoryLanguageInfo, canonicalUrl: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Standart Url" readOnly type="text" value={this.state.categoryLanguageInfo.canonicalUrl}/>
								<strong>İçerik<strong className="text-danger ml-1">*</strong></strong>
                <textarea className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({categoryLanguageInfo: {...this.state.categoryLanguageInfo, content: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="İçerik giriniz." value={this.state.categoryLanguageInfo.content} /> 
								<strong>Meta Adı<strong className="text-danger ml-1">*</strong></strong>
                <input className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({categoryLanguageInfo: {...this.state.categoryLanguageInfo, metaTitle: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Meta Adı" type="text" value={this.state.categoryLanguageInfo.metaTitle}/> 
                <strong>Meta Açıklama<strong className="text-danger ml-1">*</strong></strong>
                <textarea className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({categoryLanguageInfo: {...this.state.categoryLanguageInfo, metaDescription: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Meta Açıklama giriniz." value={this.state.categoryLanguageInfo.metaDescription} />
                <strong>Anahtar Kelimeler<strong className="text-danger ml-1">*</strong></strong>
                <input className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({categoryLanguageInfo: {...this.state.categoryLanguageInfo, keywords: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Anahtar Kelimeler" type="text" value={this.state.categoryLanguageInfo.keywords}/>
                {/* <strong>Dil Seçeneği<strong className="text-danger ml-1">*</strong></strong>
                <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={(event) => this.setState({categoryLanguageInfo: {...this.state.categoryLanguageInfo, language: event.target.value}})} value={this.state.categoryLanguageInfo.language}>
                  <option value="">Seçiniz</option>
                  {this.state.languageList.map((languageInfo, index) => <option key={index} value={languageInfo}>{languageInfo}</option>)}
                </select> */}
              </div>
              <div className="modal-footer">
                <button className="btn btn-orange" onClick={() => this.saveOnClick()}>Kaydet</button>
              </div>
            </div>
          </Modal>
          </div>
        </div>
      </div>
    )
  }
}
import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import AddressModal from '../../../component/AddressModal';
import PhoneModal from '../../../component/PhoneModal';
import CrmToMemberModal from '../../../component/crmMember/CrmToMemberModal';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';
import {isoToDate, isoToTime, isoToDateTime, priceFormat} from '../../../inc/helper';

import Select from 'react-select';

class CrmMemberEditModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenAddressModal: false,
      isOpenPhoneModal: false,
      isOpenUserNameModal: false,

      crmMember: null,
      crmMemberHistoryComment: '',
      crmMemberTypes: [],
      otherPortals: [],
      crmMemberAppointments: [],
      crmMemberHistories: [],
      categories: [],
      active: 1,
      crmMemberId: 0,

      username: '',

      editAddress: null,
      editPhone: null,

      selectedTabIndex: parseInt(this.props.searchParams.get('tab')) || 0
    };

    this.activeOnChange = this.activeOnChange.bind(this);
    this.crmMemberOtherPortalOnChange = this.crmMemberOtherPortalOnChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleKeyPressCrmHistory = this.handleKeyPressCrmHistory.bind(this);
  }

  componentDidMount() {
    this.getCrmMember();
    //this.getCrmMemberHistories();
    //this.getCrmMemberAppointments();
    //this.getCategories()
    window.scrollTo(0, 0);
  }

  componentWillMount() {
  }

  componentDidUpdate(prevProps, prevState) {
    let newTabIndex = parseInt(this.props.searchParams.get('tab')) || 0;
    if(prevState.selectedTabIndex !== newTabIndex) {
      this.setState({selectedTabIndex: newTabIndex});
    }
  }

  getCrmMember() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 6;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(this.props.navigate('/crmMembers'));
        }else{
          this.setState({isLoading: false});
        }
      }
    }

    fetch(config.restApi + 'crmMember/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({crmMember: responseData.crmMember, crmMemberId: responseData.crmMember.id});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message);
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'crmMemberHistories/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({crmMemberHistories: responseData.crmMemberHistories});
        }
        break;
        case 401:
          errorMessages.push(responseData.message).then(this.props.navigate('/crmMembers'))
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'crmMemberAppointments/tr?filterCrmMemberId=' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({crmMemberAppointments: responseData.crmMemberAppointments});
        }
        break;
        case 401:
          errorMessages.push(responseData.message).then(this.props.navigate('/crmMembers'))
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
        complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'categories/tr/' + 0, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({categories: responseData.categories});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
        complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'crmMemberTypes?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({crmMemberTypes: responseData.crmMemberTypes});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message);
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'otherPortals?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({otherPortals: responseData.otherPortals});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message);
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  /* getCategories() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'categories/tr/' + 0, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({categories: responseData.categories});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  } */

  getCrmMemberAddresses() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'crmMemberAddresses/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({crmMember: {...this.state.crmMember, crmMemberAddresses: responseData.crmMemberAddresses}});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getCrmMemberPhones() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'crmMemberPhones/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({crmMember: {...this.state.crmMember, crmMemberPhones: responseData.crmMemberPhones}});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  /* getCrmMemberAppointments() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'crmMemberAppointments/tr?filterCrmMemberId=' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({crmMemberAppointments: responseData.crmMemberAppointments});
        }
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/crmMembers'))
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getCrmMemberHistories() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'crmMemberHistories/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({crmMemberHistories: responseData.crmMemberHistories});
        }
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/crmMembers'))
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  } */

  getRegion(crmMemberAppointmentInfo) {
    let activeCrmMemberAddresses = crmMemberAppointmentInfo.crmMemberAddresses.filter(item => item.active === 1);
    return activeCrmMemberAddresses.length > 0 ? activeCrmMemberAddresses[0].cityName + ' / ' + activeCrmMemberAddresses[0].townName : '-';
  }

  activeOnChange(event) {

    let active = event.target.value === '' ? '' : parseInt(event.target.value);
    
    this.setState({crmMember: {...this.state.crmMember, active: active}});

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
    };

    fetch(config.restApi + 'crmMember/tr/active/' + active + '?id=' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      this.setState({isLoading: false});

      switch (responseData.status) {
        case 200: {}
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));

  }

  defaultSelectedOnChange(defaultSelectedStatus, index, crmMemberPhoneId) {
    let crmMemberPhones = this.state.crmMember.crmMemberPhones;
    crmMemberPhones[index].defaultSelected = defaultSelectedStatus;
    
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
    };

    fetch(config.restApi + 'crmMemberPhoneDefaultSelected/tr/' + crmMemberPhoneId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          this.setState({crmMember: {...this.state.crmMember, crmMemberPhones: crmMemberPhones}});
          this.getCrmMemberPhones();
        }
        break;
        default: {
          this.setState({isCompleted: true});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  toggleCheckbox = (otherPortalId, url) => {
    this.setState((prevState) => {
      const isChecked = this.state.crmMember.crmMemberOtherPortals.some(crmMemberOtherPortalsInfo => crmMemberOtherPortalsInfo.otherPortalId === otherPortalId);
  
      if (isChecked) {
        return {
          crmMember: {...prevState.crmMember,crmMemberOtherPortals: prevState.crmMember.crmMemberOtherPortals.filter(crmMemberOtherPortalsInfo => crmMemberOtherPortalsInfo.otherPortalId !== otherPortalId)},
        };
      } else {
        return { crmMember: {...prevState.crmMember, crmMemberOtherPortals: [...prevState.crmMember.crmMemberOtherPortals, { otherPortalId: otherPortalId, url: url}]}};
      }
    });
  };

  handleTextareaChange = (otherPortalId, url) => {
    this.setState((prevState) => ({
      crmMember: {...prevState.crmMember, crmMemberOtherPortals: prevState.crmMember.crmMemberOtherPortals.map(crmMemberOtherPortalInfo => {
          if (crmMemberOtherPortalInfo.otherPortalId === otherPortalId) {
            return {...crmMemberOtherPortalInfo, url: url};
          }
          return crmMemberOtherPortalInfo;
        }),
      },
    }));
  };

  crmMemberOtherPortalOnChange(event) {
    let crmMemberOtherPortals = this.state.crmMember.crmMemberOtherPortals;
    let value = parseInt(event.target.id);

    if (event.target.checked) {
      crmMemberOtherPortals.push(value);
    } else {
      crmMemberOtherPortals.splice(crmMemberOtherPortals.indexOf(value), 1);
    }

    this.setState({crmMember: {...this.state.crmMember, crmMemberOtherPortals: crmMemberOtherPortals}});
  }

  handleKeyPressCrmHistory(event) {
    if(event.key === 'Enter') {
      this.saveCrmMemberHistoryOnClick();
    }
  }

  saveCrmMemberHistoryOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    if (!this.state.crmMemberHistoryComment) {
      infoMessage = 'Yorum boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isCompleted: false});

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({comment: this.state.crmMemberHistoryComment})
      };

      fetch(config.restApi + 'crmMemberHistory/tr/' + this.props.searchParams.get('id'), requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200: {
            this.setState({isCompleted: true, crmMemberHistoryComment: ''});

            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Kullanıcı hakkındaki yorum başarı ile eklendi.',
              buttons: 'Tamam'
            }).then(() => this.getCrmMemberHistories());
          }
          break;
          default: {
            this.setState({isCompleted: true});

            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  deleteCrmMemberHistoryCommentOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Yorumu silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'crmMemberHistory/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});

          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Yorum başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getCrmMemberHistories());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });

            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  saveCrmMemberAddressOnClick(addressInfo, crmMemberId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({crmMemberAddresses: [addressInfo]})
    };

    fetch(config.restApi + 'crmMemberAddress/tr/' + crmMemberId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Adres başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.getCrmMemberAddresses(), this.setState({isOpenAddressModal: false}));
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  saveCrmToMemberOnClick(crmToMemberInfo, crmMemberId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({username: crmToMemberInfo.username})
    };

    fetch(config.restApi + 'crmToMember/' + crmMemberId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Üyelere başarıyla aktarıldı.',
            buttons: 'Tamam'
          }).then(() => this.getCrmMember(), this.setState({isOpenUserNameModal: false}));
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  updateCrmMemberAddressOnClick(addressInfo, crmMemberId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({crmMemberAddresses: [addressInfo]})
    };

    fetch(config.restApi + 'crmMemberAddress/tr/' + crmMemberId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Adres başarıyla güncellendi.',
            buttons: 'Tamam'
          }).then(() => this.setState({isLoading: true}, () => {this.getCrmMemberAddresses();this.setState({isLoading: false});}), this.setState({isOpenAddressModal: false}));
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteCrmMemberAddressOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Adresi silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'crmMemberAddress/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Adres başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getCrmMemberAddresses());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  saveCrmMemberPhoneOnClick(phoneInfo, crmMemberId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({crmMemberPhones: [phoneInfo]})
    };

    fetch(config.restApi + 'crmMemberPhone/tr/' + crmMemberId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Telefon numarası başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.getCrmMemberPhones(), this.setState({isOpenPhoneModal: false}));
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  updateCrmMemberPhoneOnClick(phoneInfo, crmMemberId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({crmMemberPhones: [phoneInfo]})
    };

    fetch(config.restApi + 'crmMemberPhone/tr/' + crmMemberId , requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Telefon numarası başarıyla güncellendi.',
            buttons: 'Tamam'
          }).then(() => this.setState({isLoading: true}, () => {this.getCrmMemberPhones();this.setState({isLoading: false});}), this.setState({isOpenPhoneModal: false}));
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.setState({isLoading: true}, () => {this.getCrmMemberPhones();this.setState({isLoading: false});}));
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteCrmMemberPhoneOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Telefonu silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'crmMemberPhone/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Telefon başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getCrmMemberPhones());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  handleKeyPress(event) {
    if(event.key === 'Enter') {
      this.saveCrmMemberOnClick();
    }
  }

  saveCrmMemberOnClick = (event) => {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        if (errorMessages.length === 0) {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Potansiyel üye bilgileri başarıyla kaydedildi.',
            buttons: 'Tamam'
          }).then(() => this.setState({isLoading: false}));
        } else {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: errorMessages[0],
            buttons: 'Tamam'
          }).then(() => this.setState({isLoading: false}));
        }
      }
    }

    const requestOptionPUT = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify(this.state.crmMember)
    };

    fetch(config.restApi + 'crmMember/tr/' + this.state.crmMemberId, requestOptionPUT)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {}
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    const requestOptionPOST = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({crmMemberOtherPortals: this.state.crmMember.crmMemberOtherPortals})
    };

    fetch(config.restApi + 'crmMemberOtherPortal/tr/' + this.state.crmMemberId, requestOptionPOST)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {

        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  deleteCrmMemberOnClick = () => {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Potansiyel üyeyi silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'crmMember/tr/' + this.props.searchParams.get('id'), requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});

          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Potansiyel üye başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.props.navigate('/crmMembers'));
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  saveCrmMemberFollowerOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});
    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'followedCrmMember/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      this.setState({isLoading: false});

      switch (responseData.status) {
        case 200: {
          this.getCrmMember();
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteCrmMemberFollowerOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'DELETE',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'followedCrmMember/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      this.setState({isLoading: false});

      switch (responseData.status) {
        case 200: {
          this.getCrmMember();
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  phoneFormat(phone) {
    let parsedValue = /^(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(phone);
    return parsedValue ? '(' + parsedValue[1] + ') ' + parsedValue[2] + ' ' + parsedValue[3] + ' ' + parsedValue[4] : '-';
  }

  companyCategoryType = (selectedOptions) => {
    const selectedValues = selectedOptions.map(option => option.value);

    this.setState(prevState => ({crmMember: {...prevState.crmMember, companyCategories: selectedValues}}));
  }
  
  render() {

    const companyCategories = this.state.categories.map((categoryInfo) => ({
      value: categoryInfo.id,
      label: `${categoryInfo.name}`,
      search: categoryInfo.name
    }));
    
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
      <div className="row">
        <div className="col">
          <div className="ibox">
            <div className="ibox-content">
              <div className="d-flex justify-content-between">
                <h4 className="text-darkblue">Potansiyel Üye Düzenle</h4>
                <div className="d-flex justify-content-end mb-1">
                  <div className="btn-open justify-content-center">
                    <div className="btn border border-darkblue text-center mr-2">
                      <span className="btn-open dropdown-toggle font-bold text-darkblue" data-toggle="dropdown"><i className="fa fa-ellipsis-v text-darkblue"></i></span>
                      <ul className="dropdown-menu overflow-auto mr-3 mt-2">
                        {this.state.crmMember.isFollowing === 0 ?
                          <button className="btn btn-height dropdown-item font-bold w-100" onClick={() => this.saveCrmMemberFollowerOnClick()} title="Takip Et"><i className="fa fa-heart-o m-r-xs"></i> Takip Et</button>
                        :
                          <button className="btn btn-height dropdown-item font-bold w-100" onClick={() => this.deleteCrmMemberFollowerOnClick()} title="Takipten Çıkar"><i className="fa fa-heart m-r-xs"></i> Takipten Çıkar</button>
                        }
                        {this.state.crmMember.memberId ?
                          <li><Link className="btn btn-height dropdown-item font-bold" to={'/memberEdit?id=' + this.state.crmMember.memberId} title="Üye Profiline Git"><i className="fa fa-user-o m-r-xs"></i> Üye Profiline Git</Link></li>
                        :
                        <button className="btn btn-height dropdown-item font-bold w-100" onClick={() => this.setState({isOpenUserNameModal: true})} title="Üyelere Aktar"><i className="fa fa-share m-r-xs"></i> Üyelere Aktar</button>
                        }
                        <li><Link className="btn btn-height dropdown-item font-bold" onClick={() => this.deleteCrmMemberOnClick()} title="Potansiyel Üye Sil"><i className="fa fa-trash text-darkblue m-r-xs"></i> Potansiyel Üye Sil</Link></li>
                      </ul>
                    </div>
                  </div>
                  {this.state.crmMember.isFollowing === 0 ?
                    <button className="btn btn-height btn-default btn-none mr-2" onClick={() => this.saveCrmMemberFollowerOnClick()} title="Takip Et"><i className="fa fa-heart-o"></i><span className="d-none d-sm-inline"> Takip Et</span></button>
                  :
                    <button className="btn btn-height btn-default btn-none mr-2" onClick={() => this.deleteCrmMemberFollowerOnClick()} title="Takipten Çıkar"><i className="fa fa-heart"></i><span className="d-none d-sm-inline"> Takipten Çıkar</span></button>
                  }
                  {this.state.crmMember.memberId ? 
                    <Link className="btn btn-height bg-green btn-none text-white mr-2" to={'/memberEdit?id=' + this.state.crmMember.memberId} title="Üye Profiline Git"><i className="fa fa-user-o"></i><span className="d-none d-sm-inline"> Üye Profiline Git</span></Link>
                  :
                    <button className="btn btn-height bg-orange btn-none text-white mr-2" onClick={() => this.setState({isOpenUserNameModal: true})} title="Üyelere Aktar"><i className="fa fa-share"></i><span className="d-none d-sm-inline"> Üyelere Aktar</span></button>
                  }
                  <button className="btn btn-height bg-orange btn-none text-white mr-2" onClick={() => this.deleteCrmMemberOnClick()} title="Potansiyel Üye Sil"><i className="fa fa-trash"></i><span className="d-none d-sm-inline"></span></button>
                  {this.state.selectedTabIndex !== 1 && this.state.selectedTabIndex !== 2 ? <button className="btn btn-height bg-orange text-white mr-2" onClick={() => this.saveCrmMemberOnClick()} title="Kaydet"><i className="fa fa-save"></i><span className="d-none d-sm-inline"></span></button>: ''}
                  <button className="btn btn-height btn-darkblue text-white" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i><span className="d-none d-sm-inline"></span></button>
                </div>
              </div>
              <div className="tabs-container">
                <ul className="nav nav-tabs">
                  <li><Link className={this.state.selectedTabIndex === 0 ? 'nav-link pr-1 pl-1 pr-md-2 pl-md-2 active' : 'nav-link pr-1 pl-1 pr-md-2 pl-md-2'} title="Müşteri Detay" to={'/crmMemberEdit?id=' + this.props.searchParams.get('id') + '&tab=0'}>Müşteri Detay</Link></li>
                  <li><Link className={this.state.selectedTabIndex === 1 ? 'nav-link pr-1 pl-1 pr-md-2 pl-md-2 active' : 'nav-link pr-1 pl-1 pr-md-2 pl-md-2'} title="Geçmiş Randevular" to={'/crmMemberEdit?id=' + this.props.searchParams.get('id') + '&tab=1'}>Geçmiş Randevular</Link></li>
                  <li><Link className={this.state.selectedTabIndex === 2 ? 'nav-link pr-1 pl-1 pr-md-2 pl-md-2 active' : 'nav-link pr-1 pl-1 pr-md-2 pl-md-2'} title="Potansiyel Üye Geçmişi" to={'/crmMemberEdit?id=' + this.props.searchParams.get('id') + '&tab=2'}>Potansiyel Üye Geçmişi</Link></li>
                </ul>
                <div className="tab-content">
                  <div className={this.state.selectedTabIndex === 0 ? 'tab-pane active' : 'tab-pane'}>
                    <div className="panel-body">
                      <CrmToMemberModal isOpen={this.state.isOpenUserNameModal} modalTitle="Üyeye Aktar" modalDescription="Üyeye aktarabilmek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({isOpenUserNameModal: false})} saveOnClick={crmToMemberInfo => this.saveCrmToMemberOnClick(crmToMemberInfo, this.state.crmMember.id)}></CrmToMemberModal>
                      <div className="row">
                        <div className="col">
                          <h4 className="text-darkblue">Potansiyel Müşteri</h4>
                          <div className="hr-line-dashed"></div>
                          <div className="row">
                            <div className="col-md-6">
                              <h5 className="text-darkblue"><strong>Üyelik Tipi<strong className="text-danger ml-1">*</strong></strong></h5>
                              <select className="form-control form-control-sm form-input mb-1" onChange={(event) => this.setState({crmMember: {...this.state.crmMember, crmMemberTypeId: parseInt(event.target.value) || ''}})} onKeyDown={this.handleKeyPress} value={this.state.crmMember.crmMemberTypeId}>
                                {this.state.crmMemberTypes.map(crmMemberTypeInfo => <option key={crmMemberTypeInfo.id} value={crmMemberTypeInfo.id}>{crmMemberTypeInfo.name}</option>)}
                              </select>
                            </div>
                            {this.state.crmMember.crmMemberTypeId === 2 ?
                            <div className="col-md-6">
                              <h5 className="text-darkblue"><strong>Firma Adı<strong className="text-danger ml-1">*</strong></strong></h5>
                              <input className="form-control form-control-sm form-input mb-1" onChange={event => this.setState({crmMember: {...this.state.crmMember, companyName: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Firma Adı" type="text" value={this.state.crmMember.companyName} />
                            </div> : ''}
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h5 className="text-darkblue"><strong>Müşteri Adı<strong className="text-danger ml-1">*</strong></strong></h5>
                              <input className="form-control form-control-sm form-input mb-1" onChange={event => this.setState({crmMember: {...this.state.crmMember, firstname: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')}})} onKeyDown={this.handleKeyPress} placeholder="Müşteri Adı" type="text" value={this.state.crmMember.firstname} />
                            </div>
                            <div className="col-md-6">
                              <h5 className="text-darkblue"><strong>Müşteri Soyadı<strong className="text-danger ml-1">*</strong></strong></h5>
                              <input className="form-control form-control-sm form-input mb-1" onChange={event => this.setState({crmMember: {...this.state.crmMember, lastname: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')}})} onKeyDown={this.handleKeyPress} placeholder="Müşteri Soyadı" type="text" value={this.state.crmMember.lastname} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h5 className="text-darkblue"><strong>Faaliyet Alanı</strong></h5>
                              <Select
                                isMulti
                                placeholder="Faaliyet Alanı"
                                name="colors"
                                options={companyCategories}
                                onChange={this.companyCategoryType}
                                onKeyDown={this.handleKeyPress}
                                value={companyCategories.filter(category => this.state.crmMember.companyCategories.includes(category.value))}
                                filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                                className="basic-multi-select"
                                classNamePrefix="select"
                              />
                              {/* <input className="form-control form-control-sm form-input mb-1" onChange={event => this.setState({crmMember: {...this.state.crmMember, companyType: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Faaliyet Alanı" type="text" value={this.state.crmMember.companyType} /> */}
                            </div>
                            <div className="col-md-6">
                              <h5 className="text-darkblue"><strong>Durum</strong></h5>
                              <select className="form-control form-control-sm form-input" onChange={this.activeOnChange} onKeyDown={this.handleKeyPress} value={this.state.crmMember.active}>
                                <option value="1">Açık</option>
                                <option value="0">Kapalı</option>
                              </select>        
                            </div>
                          </div>
                        </div>
                      </div>
                      <br/>
                      <div className="row">
                        <div className="col">
                          <h4 className="text-darkblue">İletişim Bilgileri</h4>
                          <div className="hr-line-dashed"></div>
                          <div className="row">
                            <div className="col-md-6">
                              <h5 className="text-darkblue"><strong>E-posta</strong></h5>
                              <input className="form-control form-input mb-1" onChange={event => this.setState({crmMember: {...this.state.crmMember, email: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="E-posta" type="email" value={this.state.crmMember.email} />
                            </div>
                            <div className="col-md-6">
                              <h5 className="text-darkblue"><strong>Web Sitesi</strong></h5>
                              <input className="form-control form-input mb-1" onChange={event => this.setState({crmMember: {...this.state.crmMember, url: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Web Sitesi" type="text" value={this.state.crmMember.url} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <br/>
                      <div className="row">
                        <div className="col">
                          <div className="d-flex justify-content-between">
                            <h4 className="text-darkblue">Adres Bilgileri</h4>
                            <button className="btn btn-orange" onClick={() => this.setState({editAddress: null, isOpenAddressModal: true})} title="Adres Ekle"><i className="fa fa-plus"></i></button>
                          </div>
                          <div className="hr-line-dashed"></div>
                          <div className="overflow-auto">
                            <table className="table table-striped table-bordered">
                            <thead>
                              <tr className="text-center text-darkblue">
                                <th>İl</th>
                                <th>İlçe</th>
                                <th>Semt</th>
                                <th>Mahalle</th>
                                <th>Açık Adres</th>
                                <th>Açıklama</th>
                                <th>Durum</th>
                                <th>İşlem</th>
                              </tr>
                            </thead>
                            <tbody className="text-center">
                              {this.state.crmMember.crmMemberAddresses.map(crmMemberAddressInfo =>
                                <tr key={crmMemberAddressInfo.id}>
                                  <td className="align-middle">{crmMemberAddressInfo.cityName}</td>
                                  <td className="align-middle">{crmMemberAddressInfo.townName}</td>
                                  <td className="align-middle">{crmMemberAddressInfo.districtName}</td>
                                  <td className="align-middle">{crmMemberAddressInfo.quarterName}</td>
                                  <td className="align-middle">{crmMemberAddressInfo.address}</td>
                                  <td className="align-middle">{crmMemberAddressInfo.description}</td>
                                  <td className="align-middle">{crmMemberAddressInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                                  <td className="align-middle" style={{minWidth:98.5}}>
                                    <button className="btn btn-orange m-1" onClick={() => {this.setState({isLoading: true, editAddress: crmMemberAddressInfo,isOpenAddressModal: true }, () => {this.setState({ isLoading: false });});}} title="Düzenle"><i className="fa fa-pencil"></i></button>
                                    <button className="btn btn-darkblue" onClick={() => this.deleteCrmMemberAddressOnClick(crmMemberAddressInfo.id)} title="Sil"><i className="fa fa-trash"></i></button>
                                  </td>
                                </tr>
                              )}
                              {this.state.crmMember.crmMemberAddresses.length === 0 ?
                                <tr>
                                  <td className="align-middle p-3" colSpan="8">Adres Bulunamadı !</td>
                                </tr>
                              : null}
                            </tbody>
                            </table>
                            <AddressModal isOpen={this.state.isOpenAddressModal} modalTitle="Adres Ekle" modalDescription="Adres bilgisi eklemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({editAddress: null, isOpenAddressModal: false})} saveOnClick={addressInfo => this.saveCrmMemberAddressOnClick(addressInfo, this.props.searchParams.get('id'))} />
                            {this.state.editAddress ?
                              <AddressModal defaultValue={this.state.editAddress} isOpen={this.state.isOpenAddressModal} modalTitle="Adres Düzenle" modalDescription="Adres bilgisini düzenlemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({editAddress: null, isOpenAddressModal: false})} saveOnClick={addressInfo => this.updateCrmMemberAddressOnClick(addressInfo, this.props.searchParams.get('id'))} />
                            : null}
                          </div>
                        </div>
                      </div>
                      <br/>
                      <div className="row">
                        <div className="col">
                          <div className="d-flex justify-content-between">
                            <h4 className="text-darkblue">Telefon Bilgileri</h4>
                            <button className="btn btn-orange" onClick={() => this.setState({editPhone: null, isOpenPhoneModal: true})} title="Telefon Ekle"><i className="fa fa-plus"></i></button>
                          </div>
                          <div className="hr-line-dashed"></div>
                          <div className="overflow-auto">
                            <table className="table table-striped table-bordered">
                            <thead>
                              <tr className="text-center text-darkblue">
                                <th>Telefon Tipi</th>
                                <th>Telefon Numarası</th>
                                <th>Ülke</th>
                                <th>Varsayılan</th>
                                <th>Durum</th>
                                <th>İşlem</th>
                              </tr>
                            </thead>
                            <tbody className="text-center">
                              {this.state.crmMember.crmMemberPhones.map((crmMemberPhoneInfo, index) =>
                                <tr key={index}>
                                  <td className="align-middle">{crmMemberPhoneInfo.phoneTypeName}</td>
                                  <td className="align-middle">{this.phoneFormat(crmMemberPhoneInfo.phoneNumber)}</td>
                                  <td className="align-middle">{crmMemberPhoneInfo.countryName}</td>
                                  <td className="align-middle"><input checked={crmMemberPhoneInfo.defaultSelected} onChange={() => this.defaultSelectedOnChange(!crmMemberPhoneInfo.defaultSelected ? 1 : 0, index, crmMemberPhoneInfo.id)} type="checkbox" /></td>
                                  <td className="align-middle">{crmMemberPhoneInfo.active == 1 ? 'Açık' : 'Kapalı'}</td>
                                  <td className="align-middle" style={{minWidth:98.5}}>
                                    <button className="btn btn-orange m-1" onClick={() => {this.setState({isLoading: true, editPhone: crmMemberPhoneInfo, isOpenPhoneModal: true }, () => {this.setState({ isLoading: false });});}} title="Düzenle"><i className="fa fa-pencil"></i></button>
                                    <button className="btn btn-darkblue" onClick={() => this.deleteCrmMemberPhoneOnClick(crmMemberPhoneInfo.id)} title="Sil"><i className="fa fa-trash"></i></button>
                                  </td>
                                </tr>
                              )}
                              {this.state.crmMember.crmMemberPhones.length === 0 ?
                                <tr>
                                  <td className="align-middle p-3" colSpan="6">Telefon Bulunamadı !</td>
                                </tr>
                              : null}
                            </tbody>
                            </table>
                            <PhoneModal isOpen={this.state.isOpenPhoneModal} modalTitle="Telefon Ekle" modalDescription="Telefon bilgisi eklemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({editPhone: null, isOpenPhoneModal: false})} saveOnClick={phoneInfo => this.saveCrmMemberPhoneOnClick(phoneInfo, this.props.searchParams.get('id'))} />
                            {this.state.editPhone ?
                              <PhoneModal defaultValue={this.state.editPhone} isOpen={this.state.isOpenPhoneModal} modalTitle="Telefon Düzenle" modalDescription="Telefon bilgisini düzenlemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({editPhone: null, isOpenPhoneModal: false})} saveOnClick={phoneInfo => this.updateCrmMemberPhoneOnClick(phoneInfo, this.props.searchParams.get('id'))} />
                            : null}
                          </div>
                        </div>
                      </div>
                      <br/>
                      <div className="row">
                        <div className="col">
                          <h4 className="text-darkblue">Detay Bilgiler</h4>
                          <div className="hr-line-dashed"></div>
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <h5 className="text-darkblue"><strong>Üye Olunan Portallar</strong></h5>
                              {this.state.otherPortals.map(otherPortalInfo =>
                                <div key={otherPortalInfo.id}>
                                  <div className="icheckbox_square-green d-block d-md-flex justify-content-between align-items-center" style={{minHeight:75}}>
                                    <label className="d-block d-md-flex pointer mt-1">
                                      <input
                                        checked={this.state.crmMember.crmMemberOtherPortals.some(crmmemberOtherPortalInfo => crmmemberOtherPortalInfo.otherPortalId === otherPortalInfo.id)}
                                        id={otherPortalInfo.id}
                                        onChange={() => this.toggleCheckbox(otherPortalInfo.id)}
                                        type="checkbox"
                                      /><span className="font-normal ml-1">{otherPortalInfo.name}</span>
                                    </label>
                                    {this.state.crmMember.crmMemberOtherPortals.map(crmmemberOtherPortalInfo => crmmemberOtherPortalInfo.otherPortalId).includes(otherPortalInfo.id) && (
                                      <input
                                      className="form-control form-input d-block d-md-flex col-md-6 m-2"
                                      onChange={(e) => this.handleTextareaChange(otherPortalInfo.id, e.target.value)}
                                      placeholder="Portal adresini yazınız."
                                      type="text"
                                      value={this.state.crmMember.crmMemberOtherPortals.find(crmmemberOtherPortalInfo => crmmemberOtherPortalInfo.otherPortalId === otherPortalInfo.id)?.url || ''}
                                    />
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="col-md-4">
                              <h5 className="text-darkblue" style={{minHeight:35}}><strong>Toplam İlan Sayısı</strong></h5>
                              <div className="input-group w-50">
                                <input className="form-control form-control-sm form-input" max="999" min="0" onChange={event => this.setState({crmMember: {...this.state.crmMember, totalAdvert: parseInt(event.target.value)}})} onKeyDown={this.handleKeyPress} type="number" value={this.state.crmMember.totalAdvert} />
                              </div>
                            </div>
                          </div>
                          <div className="hr-line-dashed"></div>
                          <div className="row">
                            <div className="col">
                              <h5 className="text-darkblue"><strong>Açıklama</strong></h5>
                              <textarea className="form-control form-input mt-1" onChange={event => this.setState({crmMember: {...this.state.crmMember, description: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Açıklama giriniz." value={this.state.crmMember.description} />
                            </div>
                          </div>
                          <div className="text-row text-right mt-2">
                            <button className="btn bg-orange text-white" onClick={() => this.saveCrmMemberOnClick()}><i className="fa fa-save"></i> Kaydet</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={this.state.selectedTabIndex === 1 ? 'tab-pane active' : 'tab-pane'}>
                    <div className="panel-body">
                      <div className="row">
                        <div className="col">
                          <div className="ibox">
                            <div className="text-darkblue">
                              <div className="d-flex justify-content-between">
                                <h4>Geçmiş Randevu Bilgileri</h4>
                                <div className="d-flex">
                                  <Link className="btn btn-darkblue" title="Randevu Ekle" to= {"/crmAppointmentAdd?id=" +  this.state.crmMember.id}><i className="fa fa-plus"></i> Randevu Ekle</Link>
                                </div>
                              </div>      
                              <div className="mt-3">
                                <h4 className="d-flex"><i className="fa fa-user-circle-o mr-2"></i>{this.state.crmMember.firstname} {this.state.crmMember.lastname}</h4>
                              </div>                      
                              <div className="hr-line-dashed"></div>
                              <div className="overflow-auto">
                                <table className="table table-striped table-bordered">
                                  <thead>
                                    <tr className="text-center text-darkblue">
                                      <th>Randevu Zamanı</th>
                                      <th>Randevu Durumu</th>
                                      <th>Satış Sorumlusu</th>
                                      <th>Bölge</th>
                                      <th>Durum</th>
                                      <th>Düzenle</th>
                                    </tr>
                                  </thead>
                                  <tbody className="text-center">
                                  {this.state.crmMemberAppointments.map(crmMemberAppointmentInfo =>
                                    <tr key={crmMemberAppointmentInfo.id}>
                                      <td className="align-middle">{isoToDateTime(crmMemberAppointmentInfo.appointmentDate)}</td>
                                      <td className="align-middle">{(crmMemberAppointmentInfo.crmMemberAppointmentStatusName)}</td>
                                      <td className="align-middle">{(crmMemberAppointmentInfo.crmMemberMarketers.map(crmMemberMarketerInfo => <h5 className="font-normal" key={crmMemberMarketerInfo.id}>{crmMemberMarketerInfo.fullName}</h5>))}</td>
                                      <td className="align-middle">{this.getRegion(crmMemberAppointmentInfo)}</td>
                                      <td className="align-middle">{crmMemberAppointmentInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                                      <td className="align-middle">
                                        <Link className="btn btn-orange text-center" to={"/crmMemberAppointmentEdit?id=" + crmMemberAppointmentInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link>
                                      </td>
                                    </tr>
                                  )}
                                  {this.state.crmMemberAppointments.length === 0 ?
                                    <tr>
                                      <td className="align-middle p-3" colSpan="6">Geçmiş Randevu Bulunamadı !</td>
                                    </tr>
                                  : null}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={this.state.selectedTabIndex === 2 ? 'tab-pane active' : 'tab-pane'}>
                    <div className="panel-body">
                      <div className="row">
                        <div className="col mb-3">
                          <strong className="text-darkblue">CRM Üye Notu<strong className="text-danger ml-1">*</strong></strong>
                          <textarea className="form-control form-input mb-2 mt-1" onChange={event => this.setState({crmMemberHistoryComment: event.target.value.trimStart()})} onKeyDown={this.handleKeyPressCrmHistory} placeholder="CRM üye hakkında not giriniz." value={this.state.crmMemberHistoryComment} />
                          <div className="float-right">
                            <button className="btn btn-darkblue" onClick={() => this.saveCrmMemberHistoryOnClick()}><i className="fa fa-save"></i> Kaydet</button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="dark-timeline" id="vertical-timeline">
                            {this.state.crmMemberHistories.map(crmMemberHistoryInfo =>
                              <div className="vertical-timeline-block p-0" key={crmMemberHistoryInfo.id}>
                                <div className="vertical-timeline-icon bg-darkblue">
                                  <i className="fa fa-comments-o text-white"></i>
                                </div>
                                <div className="vertical-timeline-content">
                                  <h3 className="font-bold text-dark">{crmMemberHistoryInfo.modifiedFullName}</h3>
                                  <h4 className="comment mb-2">{crmMemberHistoryInfo.comment}</h4>
                                  <a className="float-right mt-3" onClick={() => this.deleteCrmMemberHistoryCommentOnClick(crmMemberHistoryInfo.id)} title="Potansiyel Üye Geçmişi Sil"><i className="fa fa-trash text-darkblue"></i></a>
                                  <span className="vertical-date">{isoToDate(crmMemberHistoryInfo.modifiedDate)}<br />
                                    <h5 className="text-orange">{isoToTime(crmMemberHistoryInfo.modifiedDate)}</h5>
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          {this.state.crmMemberHistories.length === 0 ?
                            <p className="text-center">Potansiyel Üye Geçmişi Bulunamadı !</p>
                          : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>

  }
}

export default function CrmMemberEdit() {
  return (
    <CrmMemberEditModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}
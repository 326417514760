import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';

import swal from 'sweetalert';

import {CustomWidgetPrepare} from '../../component/CustomWidget';
import { getCookie } from '../../inc/cookies';
import config from '../../inc/config';
import { isoToDateTime, priceFormat } from '../../inc/helper';

class CreditProviderModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      currentStep: 0,
      creditRequestId: 0,
      remainingStep: '',
      selectedProviderId: 0,

      creditProviders: [],
      forms: [],
      groups: [],

      creditRequestInfo: {},

      datas: {
        workPlace_city: "",
        workPlace_town: "",
        workPlace_district: "",
        workPlace_quarter: "",
        homePlace_city: "",
        homePlace_town: "",
        homePlace_district: "",
        homePlace_quarter: ""
      }
    }
  }

  componentDidMount(){
    this.getAdvertDetail();
    window.scrollTo(0, 0);
  }

  getAdvertDetail() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 1;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
        if (errorMessages.length > 0) {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: errorMessages[0],
            buttons: 'Tamam'
          });
        }
      }
    }

    fetch(config.restApi + 'creditRequestEdit/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({creditRequestInfo: responseData.creditRequestInfo});
        break;
        default:
          errorMessages.push(responseData.message);
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  creditRequestEdit() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify(this.state.creditRequestInfo.datas)
    };

    fetch(config.restApi + 'creditRequestEdit/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({isLoading: false});
        break;
        default:
          swal({dangerMode: true, icon: 'info', title: 'Eksik Bilgi', text: responseData.message, buttons: 'Tamam'}).then(() => this.setState({isLoading: false}));
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}).then(() => this.setState({isLoading: false})));
  }

  advertStatus(id) {
    if(id === 1) {
      return 'Onay Bekliyor'
    } else if(id === 2) {
      return 'Yayında'
    } else if(id === 3) {
      return 'Reddedildi'
    } 
    return 'Pasif'
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="ibox">
        {
          this.state.remainingStep !== 0 ? 
          <>
            <div className="ibox-title pr-4">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="text-black">Kredi Başvuru Formu</h5>
                <div>
                  <button className="btn border bg-green text-white text-decoration-underline" onClick={() => this.creditRequestEdit()}>Kaydet<i className="fa fa-save ml-2"></i></button>
                  <Link className="btn border bg-darkblue text-white text-decoration-underline ml-1" title="Geri" to={'/creditRequestDetail?id=' + this.props.searchParams.get('id')}><i className="fa fa-reply mr-1"></i><span className="d-none d-lg-inline"></span></Link>
                </div>
              </div>
            </div>
            <div className="ibox-content">
              {
              this.state.creditRequestInfo.form.steps.map(stepInfo =>
                stepInfo.groups.map(groupInfo =>
              <div key={groupInfo.groupId}>
                <h3 className="text-center btn-gray bg-gray text-black p-2">{groupInfo.groupName}</h3>
                {stepInfo?.forms?.filter(formItem => formItem.groupId === groupInfo?.groupId)?.map((formInfo) => ( <CustomWidgetPrepare key={formInfo.optionId} defaults={this.state.creditRequestInfo?.datas} defaultOnChange={(defaults) => this.setState({ creditRequestInfo: { ...this.state.creditRequestInfo, datas: defaults }}, () => console.log(defaults))} fieldInfo={formInfo} />))}
              </div>)
              )}
              <div className="d-flex justify-content-end">
                  <div>
                    <button className="btn border bg-green text-white text-decoration-underline" onClick={() => this.creditRequestEdit()}>Kaydet<i className="fa fa-save ml-2"></i></button>
                    <Link className="btn border bg-darkblue text-white text-decoration-underline ml-1" title="Geri" to={'/creditRequestDetail?id=' + this.props.searchParams.get('id')}><i className="fa fa-reply mr-1"></i><span className="d-none d-lg-inline"></span></Link>
                  </div>
              </div>
            </div>
          </>
          :
          <div className="ibox-content d-flex justify-content-center align-items-center vh-100">
            <div className="text-center">
              <h1 className="text-green font-serif">Başvurunuz Kaydedildi !</h1>
              <h1 className="text-green font-serif mb-5">Başvurunuzun değerlendirilebilmesi için evrak yüklemeniz gereklidir !</h1>
              <Link className="btn btn-green float-center" to={'/creditRequestDetail?id=' + this.state.creditRequestId}><i className="fa fa-file mr-2"></i>Evrak Yükle</Link>
            </div>              
          </div>
        }
      </div>
  }
}

export default function CreditProviders(props) {
  return (
    <CreditProviderModule navigate={useNavigate()} searchParams={useSearchParams()[0]}>{props.children}</CreditProviderModule>
  )
}